/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

//Development
export const BASE_URL = "https://admin.api.dashboard.libt.uk";

//Localhost
// export const BASE_URL="http://localhost:3002"
